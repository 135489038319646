body, html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}

h2 {
  font-weight: normal;
  text-align: center;
  color: #64686c;
}

.box {
  padding: 3vh 3vw;
  background: #fff;
}

.card {
  text-align: center;
}

.card .MuiPaper-root {
  width: 40vw;
  height: 40vw;
  padding: 3vw 0;
}

.card div.desc {
  margin-top: 1vh;
}
.card img {
  width: 30vw;
  height: 30vw;
}

.number {
  font-size: 30px;
  font-weight: bold;
  color: rgb(76, 166, 255)
}
.numberDesc {
  color: #bfbfbf;
  font-size: 16px;
}
